<template lang="pug">
  .wc-forms-vf.feedbacks.valid
    b-form-valid-feedback
      i.fal.fa-fw.fa-check-circle
      span.text-capitalize-first
        |  {{ msg || $t('shared.forms.validations.valid') }}
</template>

<script>
export default {
  name: 'wc-forms-vf',
  props: {
    msg: String,
  },
}
</script>
