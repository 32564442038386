<template lang="pug">
  .wc-forms-if.feedbacks.invalid(:class='extraClass')
    template(v-if='remote' v-for='error in remote')
      b-form-invalid-feedback
        i.fal.fa-fw.fa-exclamation-circle
        span.text-capitalize-first
          |  {{ error }}
    template(v-for='(validator, key) in validators')
      b-form-invalid-feedback(v-if='!attribute[key]')
        i.fal.fa-fw.fa-exclamation-circle
        span.text-capitalize-first
          |  {{ $t(`shared.forms.validations.invalid.${key.split(/(?=[A-Z])/).join('_').toLowerCase()}`, validator || {} ) }}
</template>

<script>
export default {
  name: 'wc-forms-if',
  props: {
    extraClass: String,
    remote: Array,
    validators: Object,
    attribute: Object,
  },
}
</script>
