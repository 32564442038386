import Multiselect from 'vue-multiselect'
import WcFormsAlerts from '@components/shared/forms/WcFormsAlerts'
import WcFormsIf from '@components/shared/forms/WcFormsInvalidFeedbacks'
import WcFormsVf from '@components/shared/forms/WcFormsValidFeedback'
import { notify } from '@common/notifications/dispatch'
import { validationMixin } from 'vuelidate'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@assets/css/plugins/multiselect.scss'
import '@assets/css/plugins/bootstrap/forms.scss'

/**
 * @param {Array} props An array or properties.
 * @returns {Array} An array with processed properties.
 */
export function mapSelects(props = []) {
  return props.reduce((obj, prop) => {
    if (prop.collection) {
      const propOptionsMap = prop.name + 'OptionsMap'
      const computedOptionsMap = {
        get() {
          return this.formSelectOptions(
            this.$getDeep(this.form, `meta.collections.${prop.collection.data}`) || [],
            prop.collection.key,
            prop.collection.value,
            false,
            prop.collection.options
          )
        },
      }
      obj[propOptionsMap] = computedOptionsMap
      const propOptions = prop.name + 'Options'
      const computedOptions = {
        get() {
          return this.formSelectOptions(
            this.$getDeep(this.form, `meta.collections.${prop.collection.data}`) || [],
            prop.collection.key,
            prop.collection.value,
            true,
            prop.collection.options
          )
        },
      }
      obj[propOptions] = computedOptions
      const propSelected = prop.name + 'Selected'
      const computedSelected = {
        get() {
          let ids = this.$getDeep(this.form.data, prop.attribute)
          if (!ids) {
            this.$setDeep(
              this.form.data,
              prop.attribute,
              this.$getDeep(this.form.data, prop.default)
            )
            ids = this.$getDeep(this.form.data, prop.attribute)
          }
          return Array.isArray(ids)
            ? this.$getDeep(prop.collection.options, 'raw_ids')
              ? ids
              : ids.map((id) => this[propOptionsMap][id])
            : this[propOptionsMap][ids]
        },
        set(value) {
          this.$setDeep(
            this.form.data,
            prop.attribute,
            value
              ? Array.isArray(value)
                ? this.$getDeep(prop.collection.options, 'raw_ids')
                  ? value
                  : value.map((v) => {
                      return v.value
                    })
                : value[prop.collection.key_value || 'value']
              : prop.multiselect
              ? []
              : ''
          )
          const attr = this.$getDeep(eval(prop.validation) || this.$v.form.data, prop.attribute)
          attr.$touch && attr.$touch()
        },
      }
      obj[propSelected] = computedSelected
    } else if (prop.included) {
      const propSelected = prop.name + 'Selected'
      const computedSelected = {
        get() {
          let attr = this.$getDeep(this.form.data, `${prop.default}_`)
          let fault = prop.multiselect
            ? (this.$getDeep(this.form.data, prop.default) || []).reduce((obj, item) => {
                obj.push(item[prop.multiselect.key])
                return obj
              }, [])
            : this.$getDeep(this.form.data, prop.default)
          if (
            attr === undefined &&
            fault !== undefined &&
            this.form.included &&
            this.form.included.length
          ) {
            if (!Array.isArray(fault)) fault = [fault]
            attr = this.form.included
              .filter((v) => v.type === prop.included.type && fault.includes(v.id))
              .map((v) => {
                return {
                  value: this.$getDeep(v, prop.included.key),
                  label: this.$getDeep(v, prop.included.value),
                }
              })
            this.$setDeep(this.form, `selects.${prop.name}Options`, attr)
            attr = prop.multiselect ? attr : attr[0]
            this[propSelected] = attr
          }
          return attr
        },
        set(value) {
          const validate =
            this.$getDeep(this.form.data, `${prop.default}_`) === undefined ? '$reset' : '$touch'
          this.$setDeep(this.form.data, `${prop.default}_`, value || [])
          this.$setDeep(
            this.form.data,
            prop.attribute,
            value
              ? Array.isArray(value)
                ? value.map((v) => {
                    return v.value
                  })
                : value.value
              : prop.multiselect
              ? []
              : ''
          )
          this.$getDeep(this.form.data, prop.attribute) /* Bug: Need to touch data object. */
          this.$getDeep(eval(prop.validation) || this.$v.form.data, prop.attribute)[validate]()
        },
      }
      obj[propSelected] = computedSelected
    }
    return obj
  }, {})
}

/**
 * @param {Array} props An array or properties.
 * @returns {Array} An array with processed properties.
 */
export function mapNested(props = []) {
  return props.reduce((obj, prop) => {
    const computedNested = {
      get() {
        let attr = prop.many ? [] : {}
        let fault = this.$getDeep(this.form.data, prop.default.data)
        if (fault !== undefined && this.form.included && this.form.included.length) {
          if (Array.isArray(fault) ? fault[0] !== null : fault !== null) {
            if (prop.many) {
              if (!Array.isArray(fault)) fault = [fault]
              attr = this.form.included
                .filter((v) => {
                  return fault.some((i) => {
                    return v.type === i.type && v.id === i.id
                  })
                })
                .map((v) => {
                  return (v = Object.assign({}, { id: v.id, ...v.attributes }))
                })
            } else {
              attr = this.form.included.find((v) => v.type === fault.type && v.id === fault.id)
                .attributes
              attr['id'] = fault.id
            }
          } else {
            attr = this.form.included.find((v) => v.type === prop.default.type && v.id === null)
              .attributes
            attr = prop.default.attr ? Object.assign(attr, prop.default.attr) : attr
            attr = prop.many ? [attr] : attr
          }
          this[prop.name] = attr
        }
        return attr
      },
      set(value) {
        this.$setDeep(this.form.data, prop.attributes, value)
      },
    }
    obj[prop.name] = computedNested
    return obj
  }, {})
}

export const Form = {
  mixins: [notify, validationMixin],

  components: {
    Multiselect,
    WcFormsAlerts,
    WcFormsIf,
    WcFormsVf,
  },

  props: {
    action: String,
  },

  computed: {
    apiParams() {
      return {
        get: [{ id: this.action === 'update' ? parseInt(this.$route.params.id) : 'new' }],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: parseInt(this.$route.params.id) },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
    formShow() {
      return this.skipFormFill || this.isFormData
    },
    formShowErrors() {
      return !this.skipFormShowErrors && !this.isLoading && this.$getDeep(this.form, 'errors.base')
    },
    formDisable() {
      return this.$getDeep(this.form, `data.attributes.policies.${this.action}`) === false
    },
    formCreate() {
      return this.action === 'create'
    },
    formUpdate() {
      return this.action === 'update'
    },
    formDirty() {
      return this.$v.$anyDirty
    },
  },

  methods: {
    formFill(hard = true) {
      if (!this.skipFormBootstrap) this.formBootstrap(hard)
      /* Only on client side. Skip form fill and allow only on client. */
      if (!this.skipFormFill) this.isLoading = true
      if (this.skipFormFill || process.server) return
      this.apiRequest = this.formRequest(this.apiRequestFill)
      this.apiRequest.promise
        .then((response) => {
          this.$mergeDeep(this.form, response, null, this.skipFormFillNull)
          if (!this.skipNotifications) this.notifyDispatch(response)
          this.apiCallback('fill-success', response)
          this.isFormData = true
          this.isLoading = false
          return true
        })
        .catch((response) => {
          this.formRequestErrors(response)
          if (!this.skipNotifications) this.notifyDispatch(response)
          this.apiCallback('fill-error', response)
          this.isFormData = false
          this.isLoading = false
        })
      this.apiCallback('fill')
    },
    formSubmit(event) {
      if (event) event.preventDefault()
      this.apiCallback('submit-before')
      this.$v.form.data.$touch()
      if (this.$v.form.data.$invalid) {
        this.$setDeep(this.form, 'status.submit', 'invalid')
      } else {
        this.isLoading = true
        this.formBootstrap()
        this.$setDeep(this.form, 'status.submit', 'pending')
        this.apiRequest = this.formRequest()
        this.apiRequest.promise
          .then((response) => {
            this.$mergeDeep(this.form, response)
            if (!this.skipFormSubmitSuccessReset) this.$v.$reset()
            if (!this.skipNotifications) this.notifyDispatch(response)
            this.$setDeep(this.form, 'status.submit', 'ok')
            this.apiCallback('submit-success', response)
            this.isLoading = false
            return true
          })
          .catch((response) => {
            this.formRequestErrors(response)
            if (!this.skipNotifications) this.notifyDispatch(response)
            this.$setDeep(this.form, 'status.submit', 'error')
            this.apiCallback('submit-error', response)
            this.isLoading = false
          })
        this.apiCallback('submit')
      }
    },
    formSubmitData(formdata) {
      let data = Object.assign({}, formdata)
      this.skipFormData.forEach((i) => {
        this.$deleteDeep(data, i)
      })
      return data
    },
    formRequest(action = this.action, paction = null) {
      return this.apiBase[action](this.$http, ...this.apiParams[paction ? paction : action])
    },
    formRequestErrors(response) {
      if (response.errors) {
        /* Find first error with source attribute. */
        const sources = response.errors.find((obj) => {
          return obj.source && obj.source.length !== 0
        })
        this.$setDeep(this.form, 'errors.source', sources ? sources.source : {})
        /* Find all errors with a title or detail. */
        const errors = response.errors.filter((obj) => {
          return obj.title || obj.detail
        })
        this.$setDeep(this.form, 'errors.base', errors)
      }
    },
    formReset(event) {
      event.preventDefault()
      this.$v.$reset()
      this.apiCallback('reset')
      this.formFill()
    },
    formChange(event) {
      if (!['ok', 'error', 'idle'].includes(this.$getDeep(this.form, 'status.submit'))) return
      this.$setDeep(this.form, 'status.submit', 'idle')
      const el = event.target || event.srcElement
      if (el) this.formRemoveSourceErrors(el.name, event.type)
    },
    formSelectOption(value, id) {
      if (!['ok', 'error', 'idle'].includes(this.$getDeep(this.form, 'status.submit'))) return
      this.$setDeep(this.form, 'status.submit', 'idle')
      const el = document.getElementById(id)
      if (el) this.formRemoveSourceErrors(el.getAttribute('name'), 'focus')
    },
    formRemoveSourceErrors(attribute, type = 'input') {
      if (attribute) {
        if (type === 'focus') {
          this.$deleteDeep(this.$getDeep(this.form, 'errors.source'), attribute, {
            ignore_dot: true,
          })
        } else {
          clearTimeout(this.form.timer.keyup)
          this.form.timer.keyup = setTimeout(() => {
            this.$deleteDeep(this.$getDeep(this.form, 'errors.source'), attribute, {
              ignore_dot: true,
            })
          }, 600)
        }
      }
    },
    formValidationState(validation) {
      if (!validation) return null
      return validation.$dirty ? !validation.$error : null
    },
    formValidationClass(validation) {
      if (!validation) return ''
      return {
        'is-invalid': validation.$error,
        'is-valid': validation.$dirty && !validation.$error,
      }
    },
    formRemoteInvalidFeedback(field) {
      if (this.$getDeep(this.form, 'errors.source')) return this.form.errors.source[field] || []
    },
    formRemoteValid(field) {
      if (!this.$getDeep(this.form, 'errors.source')) return true
      return !this.$getDeep(this.form, 'errors.source')[field]
    },
    formBootstrap(hard = false) {
      if (hard) {
        this.form = {}
        this.isFormData = false
        this.$setDeep(
          this.form,
          'selects',
          {},
          false
        ) /* Some dropdown selects (e.g. multiselect) */
        this.formSelectAsyncData(this.asyncDataSelects)
        if (this.formBootstrapDefaults) {
          /* this.formBootstrapDefaults is a reactive object, so we need to clone
           * the object to a non-reactive object and merge.
           */
          if (this.formBootstrapDefaults.all)
            this.$mergeDeep(
              this.form,
              JSON.parse(JSON.stringify(this.formBootstrapDefaults.all)),
              null,
              false
            )
          if (this.formBootstrapDefaults[this.action])
            this.$mergeDeep(
              this.form,
              JSON.parse(JSON.stringify(this.formBootstrapDefaults[this.action])),
              null,
              false
            )
        }
      }
      this.$setDeep(this.form, 'timer', {}, false) /* Some timer (e.g. sleep) */
      this.$setDeep(this.form, 'status', {}, false) /* Some statuses (e.g. buttons) */
      this.$setDeep(
        this.form,
        'errors',
        {},
        false
      ) /* Some errors from remote (e.g. model validation) */
    },
    formShowSection(locale, namespace = '') {
      const translation = this.$v.form.data.attributes[`${locale}${namespace}Tl`]
      if (translation && translation.$error && translation.$dirty) {
        return true
      } else if (
        this.$refs[`${locale}${namespace}Tl`] &&
        this.$refs[`${locale}${namespace}Tl`].length
      ) {
        return this.$refs[`${locale}${namespace}Tl`][0].visible
      } else {
        return locale === this.$store.getters['ui/getLocale']
      }
    },
    formSelectOptions(data, field_value, field_label, array = true, options = {}) {
      if (array) {
        return data.map((v) => {
          let hash = {}
          hash[options.key_value || 'value'] = this.$getDeep(v, field_value)
          hash[options.key_label || 'label'] = this.$getDeep(v, field_label)
          return { ...hash, ...this.formSelectOptionsMeta(v, options) }
        })
      } else {
        return data.reduce((acc, v) => {
          let hash = {}
          hash[options.key_value || 'value'] = this.$getDeep(v, field_value)
          hash[options.key_label || 'label'] = this.$getDeep(v, field_label)
          return {
            ...acc,
            [this.$getDeep(v, field_value)]: { ...hash, ...this.formSelectOptionsMeta(v, options) },
          }
        }, {})
      }
    },
    formSelectOptionsMeta(data, options) {
      let meta = {}
      if (options.map) {
        meta['meta'] = {}
        for (let key of Object.keys(options.map)) {
          meta.meta[key] = this.$getDeep(data, options.map[key])
        }
      }
      return meta
    },
    formSelectAsyncData(array) {
      array.forEach((i) => {
        this.$set(this.form.selects, `${i}Options`, [])
        this.$set(this.form.selects, `${i}Loading`, false)
        this.$set(this.form.selects, `${i}Term`, '')
      })
    },
    formMultiselectSearch(term, id, action, attribute = 'name') {
      if (term.length < 2) return
      let idc = this.camelize(id)
      this.form.selects[`${idc}Loading`] = true
      this.form.selects[`${idc}Term`] = term
      clearTimeout(this.form.timer[action])
      this.form.timer[action] = setTimeout(() => {
        if (this.apiRequest && this.apiRequest.source) this.apiRequest.source.cancel()
        this.apiRequest = this.formRequest(action, `${idc}Search`)
        return this.apiRequest.promise
          .then((response) => {
            return (this.form.selects[`${idc}Options`] = this.formSelectOptions(
              response.data,
              'id',
              `attributes.${attribute}`
            ))
          })
          .finally(() => {
            this.form.selects[`${idc}Loading`] = false
          })
      }, 600)
    },
    formRemoveEmpty(params) {
      return Object.entries(params).reduce(
        (a, [k, v]) => (v === null || v === '' ? a : { ...a, [k]: v }),
        {}
      )
    },
    formDataIncluded(type, id) {
      if (this.form.included) {
        return this.form.included.find((v) => v.type === type && v.id === id)
      }
    },
    validateIf(attr, attrValidator, options = {}) {
      let hasValidated = []
      hasValidated = attrValidator.filter((v) => {
        const test = this.$getDeep(this.$v.form.data.attributes, attr)[v]
        return options['if'] === 'valid' ? test : !test
      })
      return hasValidated.length ? true : false
    },
    apiCallback(callback) {
      return callback
    },
    onCancel() {
      this.apiRequest.source.cancel()
    },
    camelize(string) {
      return string.replace(/[-_\s]([a-z])/g, (m, w) => {
        return w.toUpperCase()
      })
    },
  },

  watch: {
    $route() {
      this.formFill()
    },
  },

  data() {
    return {
      isLoading: false,
      isFormData: false,
      apiBase: null,
      apiRequest: null,
      apiRequestFill: 'get',
      apiModel: null,
      skipFormBootstrap: false,
      skipFormFill: false,
      skipFormFillNull: true,
      skipFormShowErrors: false,
      skipFormSubmitSuccessReset: false,
      skipFormData: ['policies'],
      skipNotifications: false,
      asyncDataSelects: [],
      form: {
        timer: {},
        status: {},
        selects: {},
      },
    }
  },

  created() {
    this.formFill()
  },
}
